<template>
  <Modal :show-modal="showProjectModal" @close="toggleProjectModal">
    <template #header>
      <TitleRegular
        class="text-center"
        :title="$t('customer.project_modal.title')"
      />
      <br />
      <TextRegular
        class="cursor-pointer w-fit text-etBlue-60 hover:text-etBlue-primary"
        @click="toggleTutorialModal"
      >
        {{ tutorialText }}
      </TextRegular>
    </template>
    <template #body>
      <div v-if="showVideoTutorial">
        <iframe
          class="w-full h-[500px]"
          src="https://www.youtube.com/embed/1-NhmaFBYBw"
          title="YouTube video player"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen
        />
      </div>
      <div v-else-if="account.attributes">
        <LocalLoader :is-medium="true" :loading="loading" />
        <div class="flex flex-col gap-4">
          <Select>
            <SelectLabel :title="$t('shared.label.select_workflow')" />
            <SelectSelection
              data-cy="select_flow"
              data-intercom-target="Select flow"
              :value="selected.process"
              @inputChange="onSelectedProcess"
            >
              <SelectOption
                v-for="workflow in validWorkflows"
                :id="workflow.id"
                :key="workflow.id"
                :ref="
                  (el) => {
                    processOptionsRef[workflow.attributes.display_name] = el
                  }
                "
                :value="workflow.attributes.display_name"
              >
                <span>{{ workflow.attributes.display_name }}</span>
              </SelectOption>
            </SelectSelection>
          </Select>
          <!-- If user has team of translator, at least one translato -->
          <template v-if="enableTranslation">
            <div>
              <InputField
                data-intercom-target="Project name"
                :placeholder="$t('shared.placeholder.project_name_optional')"
                :red-border="projectNameAlreadyExists"
                @inputChange="getProjectName"
              />
              <div
                v-if="projectNameAlreadyExists"
                class="text-xs mt-1 text-etRed-100"
              >
                {{ $t('customer.project.modal.name_exists') }}
                <font-awesome-icon
                  icon="fa-solid fa-triangle-exclamation"
                  color="red"
                />
              </div>
            </div>
            <div
              v-if="onShowDeadline"
              class="flex flex-col relative items-baseline"
            >
              <div class="flex gap-4 items-center">
                <BtnSecondary
                  class="!p-0 text-sm"
                  :title="$t('shared.label.click_here')"
                  @click="show.deadline = !show.deadline"
                />
                <TextTiny>
                  {{ $t('customer.project_modal.preferred_deadline.set') }}
                  <i
                    class="fas fa-question-circle relative cursor-pointer text-etBlack-50"
                    @mouseenter="show.tooltip = true"
                    @mouseleave="show.tooltip = false"
                  />
                </TextTiny>
              </div>
              <TextTiny
                v-if="show.tooltip"
                class="text-etBlack-80 leading-relaxed absolute block bg-white z-[9] p-4 rounded top-[40px] left-0 shadow-lg text-center"
              >
                {{ $t('customer.project_modal.preferred_deadline.text') }}
              </TextTiny>
              <div v-if="show.deadline" class="flex flex-col w-full mt-2">
                <TextTiny>
                  {{
                    $t('customer.project_modal.preferred_deadline_note')
                  }}</TextTiny
                >
                <div class="flex justify-between pt-3">
                  <InputDate
                    disable-past-dates
                    :placeholder="deadlineDate"
                    @inputChange="handleDate"
                  />
                  <Select>
                    <SelectSelection
                      :value="deadlineTime"
                      @inputChange="handleTime"
                    >
                      <SelectOption disabled>
                        <span>{{ $t('shared.label.choose_time') }}</span>
                      </SelectOption>
                      <SelectOption
                        v-for="time in times"
                        :key="time.name"
                        :value="time.name"
                      >
                        <span>{{ time.name }}</span>
                      </SelectOption>
                    </SelectSelection>
                  </Select>
                </div>
              </div>
            </div>
            <br v-else />
            <Select>
              <SelectSelection
                data-cy="select_source"
                data-intercom-target="Select source"
                :value="sourceText"
                @inputChange="onSelectedSource"
              >
                <SelectOption disabled>
                  <span>{{ $t('shared.label.source_lang') }}</span>
                </SelectOption>
                <SelectOption
                  v-for="source in sourceList"
                  :key="source.code"
                  :value="source.name"
                >
                  <span>{{ $t(`shared.languages.${source.code}`) }}</span>
                </SelectOption>
              </SelectSelection>
            </Select>
            <div v-if="selected.process != 'Copy editing'">
              <!-- Target languages select option -->
              <div class="flex gap-3 items-center">
                <label
                  class="flex gap-1 items-center text-sm mb-1"
                  for="optional"
                  @click="toggleAllTargets(false, false)"
                >
                  <input
                    id="optional"
                    type="radio"
                    name="method"
                    :checked="!selectAllTargets"
                  />
                  <span>{{ $t('shared.label.select_from_list') }}</span>
                </label>
                <label
                  class="flex gap-1 items-center text-sm mb-1"
                  for="all"
                  @click="toggleAllTargets(true, false)"
                >
                  <input
                    id="all"
                    type="radio"
                    name="method"
                    :checked="selectAllTargets"
                  />
                  <span>{{ $t('shared.label.select_all') }}</span>
                </label>
                <label
                  v-if="
                    isMachineTranslation &&
                    preselectedLanguages.length > 0 &&
                    account.attributes.subscription.attributes
                      .plan_identifier == 'enterprise'
                  "
                  class="flex gap-1 items-center text-sm mb-3"
                  for="preselected"
                  @click="toggleAllTargets(true, true)"
                >
                  <input
                    id="preselected"
                    type="radio"
                    name="method"
                    :checked="selectPreselectes"
                  />
                  <span>{{ $t('shared.button.preselect_languages') }}</span>
                </label>
              </div>
              <!-- Target languages -->
              <Select>
                <SelectSelection
                  data-cy="select_target"
                  data-intercom-target="Select target"
                  :value="targetText"
                  @inputChange="onSelectedTarget"
                >
                  <SelectOption disabled>
                    <span>{{ $t('shared.label.target_lang') }}</span>
                  </SelectOption>
                  <SelectOption
                    v-for="target in targetList"
                    :key="target.code"
                    :value="target.name"
                  >
                    <span>{{ $t(`shared.languages.${target.code}`) }}</span>
                  </SelectOption>
                </SelectSelection>
              </Select>
              <template v-if="selected.targets.length > 0">
                <br />
                <FilterWrap>
                  <FilterItem
                    v-for="(target, targetIndex) in selected.targets"
                    :key="targetIndex"
                    @remove="onRemoveTag(targetIndex)"
                  >
                    <span>{{ target }}</span>
                  </FilterItem>
                </FilterWrap>
              </template>
            </div>
            <BtnSecondary
              class="block ml-auto !p-0"
              :title="$t('shared.button.create_folder')"
              @click="show.newFolder = !show.newFolder"
            />
            <div
              v-if="show.newFolder"
              class="flex justify-between items-center gap-6 bg-etBlue-10 rouded p-3 py-6"
            >
              <div class="relative w-full">
                <input
                  v-model="folderName"
                  class="border border-gray-300 rounded-sm w-full"
                  :class="{ '!border-etRed-100': folderNameAlreadyExists }"
                  :placeholder="$t('shared.placeholder.folder_name')"
                  type="text"
                />
                <div
                  v-if="folderNameAlreadyExists"
                  class="absolute -bottom-[19px] text-xs mt-1 text-etRed-100"
                >
                  {{ $t('customer.folder.modal.name_exists') }}
                  <font-awesome-icon
                    icon="fa-solid fa-triangle-exclamation"
                    color="red"
                  />
                </div>
              </div>
              <BtnsWrapper class="flex gap-2 !mt-0">
                <BtnPrimary
                  :title="$t('shared.button.save')"
                  :disabled="folderNameAlreadyExists"
                  @click="onCreateNewFolder"
                />
                <BtnSecondary
                  class="!px-2"
                  :title="$t('shared.button.cancel')"
                  @click="show.newFolder = false"
                />
              </BtnsWrapper>
            </div>
            <Select>
              <SelectSelection
                data-cy="select_folder"
                :value="folderText"
                @inputChange="onSelectedFolder"
              >
                <SelectOption disabled>
                  <span>{{ $t('shared.placeholder.project_folder') }}</span>
                </SelectOption>
                <SelectOption
                  v-for="(folder, i) in allFolders"
                  :key="folder.attributes.created_at + i"
                  :value="folder.attributes.name"
                >
                  <span>{{ folder.attributes.name }}</span>
                </SelectOption>
              </SelectSelection>
            </Select>
            <DragAndDrop
              data-intercom-target="Drag and drop"
              :custom-supported-formats="supportedFiles"
              :text="$t('shared.placeholder.upload')"
              @filesAdded="filesAdded"
            />
            <div
              v-if="selected.process != 'Machine Translation' && !selected.isAi"
            >
              <SelectLabel
                :title="$t('shared.label.specific_instructions.customer')"
              />
              <Textarea
                class="min-h-[50px]"
                :value="instructionText"
                vertical
                :placeholder="$t('shared.label.specific_instructions_text')"
                @inputChange="getInstructions"
              />
            </div>
          </template>
          <template v-else>
            <InfoBlock>
              <TextRegular>
                <i18n-t
                  keypath="customer.create_project.modal.missing_translators"
                  tag="p"
                >
                  <template #here>
                    <router-link
                      :to="{ name: 'clientTeamStepOne' }"
                      @click="toggleProjectModal"
                    >
                      <span>{{ $t('shared.button.here') }}</span>
                    </router-link>
                  </template>
                </i18n-t>
              </TextRegular>
            </InfoBlock>
          </template>
        </div>
      </div>
    </template>
    <template v-if="!showVideoTutorial" #footer>
      <BtnsWrapperVertical>
        <BtnPrimary
          data-cy="create_project_button"
          data-intercom-target="Create project"
          class="w-full"
          :title="$t('shared.button.create_project')"
          :disabled="disableButton"
          @click="onCreateProject"
        />
        <BtnSecondary
          :title="$t('shared.button.cancel')"
          @click="toggleProjectModal"
        />
      </BtnsWrapperVertical>
    </template>
  </Modal>
</template>
<script setup>
import DragAndDrop from '@/components/shared/DragAndDrop'
import InfoBlock from '@/components/shared/InfoBlock'
import Modal from '@/components/shared/Modal'
import BtnPrimary from '@/components/shared/btn/BtnPrimary'
import BtnSecondary from '@/components/shared/btn/BtnSecondary'
import BtnsWrapper from '@/components/shared/btn/BtnsWrapper'
import BtnsWrapperVertical from '@/components/shared/btn/BtnsWrapperVertical'
import FilterItem from '@/components/shared/filter/new/FilterItem'
import FilterWrap from '@/components/shared/filter/new/FilterWrap'
import TextRegular from '@/components/shared/font/text/TextRegular'
import TextTiny from '@/components/shared/font/text/TextTiny'
import TitleRegular from '@/components/shared/font/title/TitleRegular'
import InputDate from '@/components/shared/input/InputDate'
import InputField from '@/components/shared/input/InputField'
import LocalLoader from '@/components/shared/loaders/LocalLoader'
import Select from '@/components/shared/select/Select'
import SelectLabel from '@/components/shared/select/SelectLabel'
import SelectOption from '@/components/shared/select/SelectOption'
import SelectSelection from '@/components/shared/select/SelectSelection'
import Textarea from '@/components/shared/textarea/Textarea'
import i18n from '@/i18n'
import moment from 'moment'
import { computed, ref, watch } from 'vue'
import { useStore } from 'vuex'

const store = useStore()
const $t = i18n.t

const loading = ref(false)
const folderName = ref('')
const selected = ref({
  process: 'Human translation',
  source: null,
  target: null,
  projectName: '',
  targets: [],
  files: [],
  isAi: false,
  folder: {
    name: null,
    id: null
  },
  deadline: {
    date: null,
    time: null,
    dateTime: null
  }
})
const show = ref({
  deadline: false,
  newFolder: false,
  tooltip: false
})
const selectAllTargets = ref(false)
const selectPreselectes = ref(false)
const showVideoTutorial = ref(false)
const instructionText = ref(null)
const processOptionsRef = ref({})

const allProjects = computed(() => store.state.workspace.allProjects)
const currentUser = computed(() => store.state.workspace.currentUser)
const currentAccount = computed(() => store.state.workspace.currentAccount)
const showProjectModal = computed(() => store.state.modals.showProjectModal)
const languages = computed(() => store.state.account.languages)
const accountLangPair = computed(() => store.state.account.accountLangPair)
const account = computed(() => store.state.account.account)
const allFolders = computed(() => store.state.folder.allFolders)
const translators = computed(() => store.state.translators.translators)
const currentAccountId = computed(
  () => store.getters['workspace/currentAccountId']
)
const supportedFiles = computed(() => {
  const isAiWorkflow = account.value.attributes.workflows.find(
    (w) => w.attributes.display_name === selected.value.process
  ).attributes.is_ai_translation
  const aiSupported =
    '.docx, .xlsx, .xlf, .pptx, .ott, .ods, .ots, .otp, .html, .xhtml, .sdlxliff, .mxliff, .xliff, .po, .ttx, .mif, .idml, .icml, .dita, .csv, .tsv, .xml, .dtd, .json, .yaml, .txt, .properties, .resx, .strings, .srt, .wix'
  return isAiWorkflow ? aiSupported : ''
})
const folderNameAlreadyExists = computed(() => {
  const foundName = allFolders.value.find(
    (obj) =>
      obj.attributes.name.toUpperCase() === folderName.value.toUpperCase()
  )
  if (foundName) {
    return true
  } else return false
})

const projectNameAlreadyExists = computed(() => {
  const foundName = allProjects.value.find(
    (obj) =>
      obj.attributes.name.toUpperCase() ===
      selected.value.projectName.toUpperCase()
  )
  if (foundName) {
    return true
  } else return false
})

const isMachineTranslation = computed(() => {
  const workflow = account.value.attributes.workflows.find(
    (w) => w.attributes.display_name === selected.value.process
  ).attributes
  return workflow.is_machine_translation && !workflow.is_ai_translation
})
const enableTranslation = computed(() => {
  const isAiWorkflow = account.value.attributes.workflows.find(
    (w) => w.attributes.display_name === selected.value.process
  ).attributes.is_ai_translation
  return (
    translators.value.length > 0 ||
    selected.value.process === 'Machine Translation' ||
    isAiWorkflow
  )
})
const onShowDeadline = computed(() => {
  return selected.value.process != 'Translate Yourself'
})
const sourceText = computed(() => {
  if (selected.value.source) return selected.value.source
  return $t('shared.label.source_lang')
})
const targetText = computed(() => {
  if (selected.value.target) return selected.value.target
  return $t('shared.label.target_lang')
})
const folderText = computed(() => {
  if (selected.value.folder.name) return selected.value.folder.name
  return $t('shared.placeholder.project_folder')
})
const deadlineDate = computed(() => {
  if (selected.value.deadline.date) return selected.value.deadline.date
  return $t('shared.label.choose_date')
})
const deadlineTime = computed(() => {
  if (selected.value.deadline.time) return selected.value.deadline.time
  return $t('shared.label.choose_time')
})
const times = computed(() => {
  let times = []
  for (let i = 0; i < 24; i++) {
    times.push({
      value: i,
      name: `${i < 10 ? '0' : ''}${i}:00`
    })
  }
  return times
})
const processIdentifier = computed(() => {
  const process = processOptionsRef.value.find(
    (p) => p.attributes.display_name === selected.value.process
  )
  if (process) {
    return process.attributes.identifier
  } else {
    return ''
  }
})
const showAllLanguages = computed(() => {
  return (
    processIdentifier.value === 'self+machine_translation' ||
    !accountLangPair.value[processIdentifier.value]
  )
})
const sourceList = computed(() => {
  if (showAllLanguages.value) return languages.value.source
  return accountLangPair.value[processIdentifier.value]
})

const preselectedLanguages = computed(() => {
  const list =
    selected.value.source &&
    Object.values(account.value.attributes.language_pairs.translation).find(
      (source) => source.name === selected.value.source
    )
  if (list) {
    return list.target_languages
  } else {
    return []
  }
})

const targetList = computed(() => {
  if (showAllLanguages.value && !selectPreselectes.value)
    return languages.value.target
  const list = !selectPreselectes.value
    ? selected.value.source &&
      Object.values(sourceList.value).find(
        (source) => source.name === selected.value.source
      ).target_languages
    : preselectedLanguages.value
  if (list) {
    return list
  } else {
    return []
  }
})
const disableButton = computed(() => {
  if (selected.value.process == 'Copy editing') {
    return !selected.value.source || projectNameAlreadyExists.value
  } else if (
    selected.value.files.length > 0 &&
    selected.value.targets.length > 0 &&
    selected.value.source &&
    !loading.value &&
    !projectNameAlreadyExists.value
  ) {
    return false
  } else {
    return true
  }
})
const tutorialText = computed(() => {
  return showVideoTutorial.value
    ? $t('shared.button.go_back')
    : $t('customer.project_modal.video_btn')
})
const validWorkflows = computed(() => {
  const aiFlowAllowed =
    account.value.attributes.features.translation_flow === 'ai'
  return account.value.attributes.workflows.filter(
    (w) =>
      !w.attributes.has_copywriting_steps &&
      (aiFlowAllowed || w.attributes.identifier !== 'ai+translation')
  )
})
const createNewFolder = (payload) =>
  store.dispatch('workspace/createNewFolder', payload)
const createProject = (payload) =>
  store.dispatch('projects/createProject', payload)
const reportTrackingEvent = (payload) =>
  store.dispatch('teams/reportTrackingEvent', payload)
const toggleProjectModal = () => store.commit('modals/toggleProjectModal')

function toggleAllTargets(all, isPreselect) {
  selectPreselectes.value = false
  if (isPreselect) {
    selectPreselectes.value = true
  }
  if (all && targetList.value) {
    selected.value.targets = targetList.value.map((target) => target.name)
  } else {
    selected.value.targets = []
  }
  selectAllTargets.value = all
}
function handleDate(date) {
  let format = moment(date).format('YYYY-MM-DD')
  selected.value.deadline.date = format
}
function handleTime(value) {
  selected.value.deadline.time = value
}
function onSelectedProcess(processType) {
  const selectedFlow = validWorkflows.value.find(
    (flow) => flow.attributes.display_name === processType
  )

  selected.value.process = processType
  selected.value.source = null
  selected.value.target = null
  selected.value.targets = []
  selected.value.isAi = selectedFlow.attributes.is_ai_translation
  selectAllTargets.value = false
}
function onSelectedSource(sourceType) {
  selected.value.source = sourceType
  selected.value.target = null
  selected.value.targets = []
  selectAllTargets.value = false
  selectPreselectes.value = false
}
function onSelectedTarget(targetType) {
  if (selected.value.targets.includes(targetType)) return
  selected.value.targets.push(targetType)
  selected.value.target = targetType
}
function onSelectedFolder(folder) {
  let folders = allFolders.value
  let selectedFolder
  folders.forEach((obj) => {
    if (obj.attributes.name == folder) {
      selectedFolder = {
        name: obj.attributes.name,
        id: obj.id
      }
      return
    }
  })
  selected.value.folder = selectedFolder
}
function onRemoveTag(i) {
  selected.value.targets.splice(i, 1)
}
function onCreateNewFolder() {
  createNewFolder({ folderName: folderName.value }).finally(() => {
    show.value.newFolder = false
    folderName.value = ''
  })
}
function filesAdded(files) {
  selected.value.files = files
}
function getProjectName(project) {
  selected.value.projectName = project.value
}
function getInstructions(value) {
  instructionText.value = value
}
async function pushGA4FunnelEvent() {
  if (
    currentAccount?.value?.tracking?.active_action_at === null &&
    isMachineTranslation.value
  ) {
    window.dataLayer = window.dataLayer || []
    window.dataLayer.push({
      event: 'funnel',
      funnel_name: '3_freemium_active',
      first_name: currentUser?.value?.attributes?.first_name || '',
      last_name: currentUser?.value?.attributes?.last_name || '',
      phone: currentUser?.value?.attributes?.phone || '',
      email: currentUser?.value?.attributes?.email || '',
      team_name: currentAccountId.value,
      user_id: currentUser.value.id,
      action: 'Created MT project',
      nonInteraction: true
    })
    await reportTrackingEvent({ isPaidAction: false })
  }
}
function onCreateProject() {
  loading.value = true
  const data = {
    workflow_id:
      processOptionsRef.value[selected.value.process].$el.getAttribute('id'),
    process: selected.value.process,
    files: selected.value.files,
    source: selected.value.source,
    folderId: selected.value.folder.id,
    projectName: selected.value.projectName,
    deadline: selected.value.deadline.dateTime
      ? moment(selected.value.deadline.dateTime)
          .subtract(moment(selected.value.deadline.dateTime).utcOffset(), 'm')
          .format('YYYY-MM-DD HH:mm:ss')
      : null,
    instructions: instructionText.value,
    targets: []
  }
  if (selected.value.process == 'Copy editing') {
    selected.value.targets = ['English (United States)']
  }
  languages.value.target.forEach((lang) => {
    if (selected.value.targets.includes(lang.name)) {
      return data.targets.push(lang.code)
    }
  })
  languages.value.source.forEach((lang) => {
    if (selected.value.source.includes(lang.name)) {
      return (data.source = lang.code)
    }
  })
  for (const process of processOptionsRef.value) {
    if (process.name === data.process) {
      data.process = process.type
      break
    }
  }
  createProject(data)
    .then(() => {
      pushGA4FunnelEvent()
    })
    .finally(() => {
      loading.value = false
    })
}
function toggleTutorialModal() {
  showVideoTutorial.value = !showVideoTutorial.value
}

watch(
  () => selected.value.targets,
  (targets) => {
    if (targets.length === 0) {
      selected.value.target = null
    }
  }
)
watch(
  () => selected.value.deadline,
  (targets) => {
    if (targets.date && targets.time) {
      for (const time of times.value) {
        if (time.name == targets.time) {
          const timeAndDateFormat = moment(targets.date)
            .set('hour', time.value)
            .format('YYYY-MM-DD HH:mm:ss')
          selected.value.deadline.dateTime = timeAndDateFormat
        }
      }
    }
  },
  { deep: true, immediate: true }
)
watch(
  () => showProjectModal.value,
  (isOpen) => {
    if (!isOpen) {
      loading.value = false
      folderName.value = ''
      selected.value = {
        process: 'Human translation',
        source: null,
        target: null,
        projectName: '',
        targets: [],
        files: [],
        isAi: false,
        folder: {
          name: null,
          id: null
        },
        deadline: {
          date: null,
          time: null,
          dateTime: null
        }
      }
      show.value = {
        deadline: false,
        newFolder: false,
        tooltip: false
      }
      selectAllTargets.value = false
      selectPreselectes.value = false
      showVideoTutorial.value = false
      instructionText.value = null
    }
    processOptionsRef.value = validWorkflows.value
  }
)
</script>
