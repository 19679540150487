<template>
  <div class="mt-auto bg-etBlue-10 w-full p-3 rounded">
    <div
      class="mr-12 text-etBlack-100 flex flex-col gap-2 justify-end pr-[60px] tabletPortrait:gap-4 tabletPortrait:flex-row"
    >
      <div v-if="perPage" class="flex gap-2 items-center">
        <span class="text-sm text-etBlack-100 block">{{
          $t('shared.pagination.items_text')
        }}</span>
        <Select class="text-sm">
          <SelectSelection :value="perPage" @input-change="changePerPage">
            <SelectOption
              v-for="perPageOption in perPageOptions"
              :key="perPageOption"
              :value="perPageOption"
            >
              <span>{{ perPageOption }}</span>
            </SelectOption>
          </SelectSelection>
        </Select>
      </div>
      <div class="flex gap-2 items-center">
        <span class="text-sm text-etBlack-100 block">{{
          $t('shared.pagination.page_text')
        }}</span>
        <Select class="text-sm mr-2">
          <SelectSelection
            :value="selectedPage"
            @input-change="$emit('onChange', $event)"
          >
            <SelectOption v-for="page in pageRange" :key="page" :value="page">
              <span>{{ page }}</span>
            </SelectOption>
          </SelectSelection>
        </Select>
        <button
          class="bg-white border border-etBlack-10 cursor-pointer rounded p-3 h-full hover:bg-etBlack-10"
          :class="{ '!cursor-not-allowed hover:!bg-white': disabledDecrease }"
          :disabled="disabledDecrease"
          @click="onChange(-1)"
        >
          <i class="fas fa-chevron-left" />
        </button>
        <button
          class="bg-white border border-etBlack-10 cursor-pointer rounded p-3 h-full hover:bg-etBlack-10"
          :class="{ '!cursor-not-allowed hover:!bg-white': disabledIncrease }"
          :disabled="disabledIncrease"
          @click="onChange(1)"
        >
          <i class="fas fa-chevron-right" />
        </button>
      </div>
    </div>
  </div>
</template>
<script setup>
import Select from '@/components/shared/select/Select'
import SelectOption from '@/components/shared/select/SelectOption'
import SelectSelection from '@/components/shared/select/SelectSelection'
import i18n from '@/i18n'
import { computed, ref, watch } from 'vue'
import { useRoute } from 'vue-router'

const route = useRoute()
const $t = i18n.t

const emit = defineEmits(['onChange', 'changePerPage'])

const props = defineProps({
  currentPage: Number,
  totalPages: Number,
  perPage: Number
})

const pageRange = ref([])
const perPageOptions = ref([15, 25, 50, 100])
const selectedPage = ref(1)

const disabledDecrease = computed(() => {
  if (selectedPage.value > 1) return false
  return true
})
const disabledIncrease = computed(() => {
  if (selectedPage.value < props.totalPages) return false
  return true
})

watch(
  () => props.totalPages,
  (newVal) => {
    pageRange.value = range(1, newVal)
  },
  { immediate: true }
)
watch(
  () => props.currentPage,
  () => {
    selectedPage.value = props.currentPage
  }
)
watch(
  () => route.query.page,
  (page) => {
    if (page) {
      selectedPage.value = page
    } else {
      selectedPage.value = props.currentPage
    }
  },
  { immediate: true }
)

function range(min, max) {
  return [...Array(max - min + 1).keys()].map((i) => i + min)
}

function onChange(num) {
  const pageNum = num + props.currentPage
  emit('onChange', pageNum)
}

function changePerPage(value) {
  emit('changePerPage', Number(value))
}
</script>
